/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const router = {
  path: '/home/huayang',
  component: Layout,
  children: [{
    path: '',
    name: 'index',
    meta: {
      title: "中介超市"
    },
    component: () =>
      import('@/views/huayang/index.vue')
  }, {
    path: '/home/interaction',
    name: 'interaction',
    meta: {
      title: "政企互动"
    },
    component: () =>
      import('@/views/huayang/interaction.vue')
  }, {
    path: '/home/classroom',
    name: 'classroom',
    meta: {
      title: "知识大讲堂"
    },
    component: () =>
      import('@/views/huayang/classroom.vue')
  }, {
    path: '/home/classroom/particulars/:Id',
    name: 'particulars',
    meta: {
      title: "详情"
    },
    component: () =>
      import('@/views/huayang/particulars.vue')
  }, {
    path: '/home/huayang/company/:Id',
    name: 'company',
    meta: {
      title: "企业详情"
    },
    component: () =>
      import('@/views/huayang/company.vue')
  }, {
    path: '/home/huayang/firmlogin',
    name: 'firmlogin',
    meta: {
      title: "企业登录"
    },
    component: () =>
      import('@/views/huayang/firmlogin.vue')
  }, {
    path: '/home/interaction/lianluo',
    name: 'lianluo',
    meta: {
      title: "联系我们"
    },
    component: () =>
      import('@/views/huayang/lianluo.vue')
  }, {
    path: '/home/serve',
    name: 'serve',
    meta: {
      title: "资源看板"
    },
    component: () =>
      import('@/views/huayang/serve.vue')
  }, {
    path: '/home/huayang/firm/comment',
    name: 'comment',
    meta: {
      title: "服务评价"
    },
    component: () =>
      import('@/views/huayang/comment.vue')
  }, {
    path: '/home/huayang/firm',
    name: 'firm',
    meta: {
      title: "企业端"
    },
    component: () =>
      import('@/views/huayang/firm.vue')
  }, {
    path: '/home/huayang/firm/comment/resource',
    name: 'resource',
    meta: {
      title: "发布资源清单"
    },
    component: () =>
      import('@/views/huayang/resource.vue')
  }, {
    path: '/home',
    name: 'home',
    meta: {
      title: "首页"
    },
    component: () =>
      import('@/views/huayang/home.vue')
  }, {
    path: '/home/introduce',
    name: 'introduce',
    meta: {
      title: "企业资讯"
    },
    component: () =>
      import('@/views/huayang/introduce.vue')
  },
  {
    path: '/home/interaction/department',
    name: 'department',
    meta: {
      title: "部门职能"
    },
    component: () =>
      import('@/views/huayang/department.vue')
  },
  {
    path: '/home/interaction/introduces',
    name: 'introduces',
    meta: {
      title: "园区简介"
    },
    component: () =>
      import('@/views/huayang/introduces.vue')
  },
  {
    path: '/home/interaction/Star',
    name: 'Star',
    meta: {
      title: "明星企业"
    },
    component: () =>
      import('@/views/huayang/Star.vue')
  },
  {
    path: '/home/dynamic',
    name: 'dynamic',
    meta: {
      title: "业界动态"
    },
    component: () =>
      import('@/views/huayang/dynamic.vue')
  },
  ]
}

export default router